import React, { useEffect, useState } from 'react';
import Nav from './Nav';
import { StaticImage } from 'gatsby-plugin-image';
import MediaQuery from 'react-responsive';
import Icon from '../assets/images/mappin_1.svg';
const scrollTop = () => {
  return Math.max(
    window.pageYOffset,
    document.documentElement.scrollTop,
    document.body.scrollTop
  );
};

export default function SideBar({ fullMenu }) {
  const [headerOpen, toggleHeader] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const onScroll = () => {
    const position = scrollTop();
    if (position >= 3430) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };
  useEffect(() => {
    document.addEventListener('scroll', onScroll);
    return () => document.removeEventListener('scroll', onScroll);
  });
  return (
    <header id="header" className={`${fullMenu ? '' : 'alt'}`}>
      {isTop ? (
        <>
          <MediaQuery minWidth={401}>
            <div
              style={{
                width: '100px',
              }}
            >
              <StaticImage
                src="../assets/images/mappin.png"
                style={{
                  width: '100px',
                  marginTop: '12px',
                  marginLeft: '10px',
                }}
                alt="logo"
                placeholder="blurred"
              />
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={400}>
            <div
              style={{
                width: '75px',
              }}
            >
              <StaticImage
                src="../assets/images/mappin.png"
                style={{
                  width: '75px',
                  marginTop: '12px',
                  marginLeft: '10px',
                }}
                alt="logo"
                placeholder="blurred"
              />
            </div>
          </MediaQuery>
          <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
            <Nav onMenuToggle={() => toggleHeader(!headerOpen)} />
          </div>
        </>
      ) : (
        <></>
      )}
    </header>
  );
}
