import React from 'react';
import config from '../../config';
import { StaticImage } from 'gatsby-plugin-image';
import MediaQuery from 'react-responsive';
export default function Footer() {
  return (
    <footer id="footer">
      <MediaQuery minWidth={401}>
        <StaticImage
          src="../assets/images/mappin.png"
          style={{
            width: '300px',
            marginBottom: '15px',
          }}
        />
      </MediaQuery>
      <MediaQuery maxWidth={400}>
        <StaticImage
          src="../assets/images/mappin.png"
          style={{
            width: '200px',
            marginBottom: '15px',
          }}
        />
      </MediaQuery>

      <header className="major">
        <h2>COMPANY</h2>
        <br />
        <h6>会社概要</h6>
        <table>
          <tbody>
            <tr>
              <th>社名</th>
              <td>mappin株式会社</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>
                大阪市北区角田町１番１２号 阪急ファイブアネックスビル GVH#5 2階
              </td>
            </tr>
            <tr>
              <th>代表取締役</th>
              <td>吉元台</td>
            </tr>

            <tr>
              <th>事業内容</th>
              <td>クラウド型ソフトウェアの開発、販売</td>
            </tr>
            <tr>
              <th>資本金</th>
              <td>9,500,000円</td>
            </tr>
          </tbody>
        </table>
      </header>
      <ul className="icons">
        {config.socialLinks.map((social) => {
          const { style, icon, name, url } = social;
          return (
            <li key={url}>
              <a href={url} className={`icon ${style} ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li>&copy; mappin株式会社</li>
      </ul>
    </footer>
  );
}
