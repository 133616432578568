module.exports = {
  siteTitle: 'mappin株式会社', // <title>
  manifestName: 'Spectral',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'ITで医療をもっと便利に',
  subHeading1: `現代社会の中で、医療との関わりは避けて通れません。`,
  subHeading2: `しかし、医療分野のIT化はまだまだ他の分野に比べて出遅れています。`,
  subHeading3: `mappinは医療従事者ならではの視点で、医療の中の煩雑な手続きや業務をITで解決します。`,
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/mappinmedical',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:asada@mappin.jp',
    },
  ],
};
